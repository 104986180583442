<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('Students')"
      icon="mdi-account-multiple"

      :filters="filtersItems"
      :filters-search="ajaxSearch"
      fill-height
      :no-content-condition="items.length === 0"
      :no-content-button-action="Object.keys(filtersValues).length === 0  ? displayNewStudent : null"
      :no-content-button-text="Object.keys(filtersValues).length === 0  ? 'AddStudent' : null"
      :no-content-text="Object.keys(filtersValues).length > 0 ? 'NothingFound' : 'NoStudentsMessage'"
      :no-content-icon="Object.keys(filtersValues).length > 0 ? 'mdi-magnify' : 'mdi-account-multiple'"

      :header-dropdown-actions="[{text : '123' , value : '123'}]"

      :loading="loading"
  >



    <template #default>
      <!-- Action Button -->
      <portal v-if="items.length > 0" to="dash_course.header">
        <div class="d-flex ">
          <ws-tooltip
              v-if="IS_BUSINESS_OWNER || !MODULE_PERMISSIONS(['MODULE_WESTUDY__HIDE_STUDENT_CONTACTS'], true)"
              text="ExportData"
          >
            <ws-button
                @click="exportData"
                :color="wsDARKLIGHT" outlined icon="mdi-download" min-width="30px"
                class="mr-1"
            />
          </ws-tooltip>

          <ft-select
              @input="displayAction"
              :items="addStudentSelectActionTypes"
          >
            <ws-button label="Add" left-icon="mdi-plus" :click-stop="false" />
          </ft-select>


        </div>

      </portal>

        <!-- Groups and Students -->
        <v-row  no-gutters  style="flex-wrap: nowrap; height: 100% !important" class="fill-height mt-5"  >

          <!-- Groups -->
          <v-col :style="`min-width: ${categoriesWidth}px; max-width: ${categoriesWidth}px; height: 100% !important;`"
                 style="transition: all 0.3s ease"
                 class="pr-4 pb-6 flex-grow-0 flex-shrink-0 sideTransition fill-height"
          >

            <v-sheet v-if="!hideCategories"
                     class="fill-height wsRoundedLight overflow-y-auto"
                     :style="`border : 1px solid ${wsBACKGROUND}`"
            >
              <!-- header-->
              <v-sheet :color="wsLIGHTCARD" height="46"
                       class="d-flex  wsRoundedLight align-center"
                       :class="!hideCategories ? 'px-2 justify-space-between' : 'justify-center'"
              >
                <ws-tooltip v-if="!hideCategories" :text="$t('AddGroup')">
                  <v-btn :color="wsACCENT"  @click="displayNewGroup()" icon>
                    <v-icon >mdi-folder-plus-outline</v-icon>
                  </v-btn>
                </ws-tooltip>

                <h4 v-if="!hideCategories" class="pl-4" > {{ $t('Groups') }}</h4>

                <v-btn @click="hideCategoriesAction" :color="wsACCENT" icon><v-icon>mdi-chevron-{{ !hideCategories ?  'left' : 'right' }}</v-icon></v-btn>

              </v-sheet>

              <v-divider   :style="`border-color : ${wsBACKGROUND}`" ></v-divider>

              <!-- Offline Groups -->
              <template  v-if="!$store.state.courses.course.online && !hideCategories">

                <v-hover v-for="(item,i) in groupsFiltered" :key="'offline_group' + i"
                         v-slot="{hover}"  >
                  <v-sheet
                      @click="selectGroup(item.value)" height="48"
                      :color="hover || $store.state.settings.filtersData.group === item.value ? wsLIGHTCARD : ''"
                      :style="`border-bottom : 1px solid ${wsBACKGROUND}`"
                      class="d-flex align-center justify-space-between px-4 pointer"
                  >
                    <h5 :style="`color : ${wsACCENT}`" class="d-flex align-center">
                      {{  item.text  }}

                      <wsAddItem
                          v-if="item.value !== 'all' && item.value !== 'unsorted'"
                          inline
                          @input="editGroupAction($event,item)"
                          :hover="hover"
                          :items="offlineGroupActionsSelect"
                      />

                    </h5>
                    <!--                        <h5>{{  courseCategoryCount(item.value)  }}</h5>-->
                  </v-sheet>
                </v-hover>

              </template>
              <!-- Scheduled Groups -->
              <template  v-if="$store.state.courses.course.online && $store.state.courses.course.scheduled && !hideCategories">

                <template v-for="(item,i) in enrollmentWavesSelect" >
                  <!-- Enrolment Waves -->
                  <v-hover v-slot="{hover}"  :key="'schedule_group' + i">
                    <v-sheet
                        :style="`border-bottom : 1px solid ${wsBACKGROUND}`"
                        class="d-flex align-center justify-space-between px-4 pointer"
                        height="48"
                    >
                      <h5 :style="`color : ${wsACCENT}`" class="d-flex align-center">
                        {{  item.text  }}

                        <ws-tooltip  :text="$t('AddGroup')">
                          <v-btn
                              v-if="hover"
                              @click="displayNewGroup();entityData.enrollment_wave_id = item.value"
                              icon :color="wsACCENT"><v-icon>mdi-folder-plus-outline</v-icon></v-btn>
                        </ws-tooltip>


                      </h5>
                      <!--                        <h5>{{  courseCategoryCount(item.value)  }}</h5>-->
                    </v-sheet>
                  </v-hover>
                  <template v-for="(child,j) in getEnrollmentWaveGroups(item.value)">
                    <v-sheet  v-if="!hideCategories"
                              height="48"
                              :key="'scheduled_group_' + i + j"
                    >
                      <v-hover v-slot="{hover}" >

                        <div class="fill-height">
                          <v-sheet
                              @click="$store.state.settings.filtersData.group = child.value"
                              :color="hover || $store.state.settings.filtersData.group === item.value ? wsLIGHTCARD : ''"
                              :style="`border-bottom : 1px solid ${wsBACKGROUND}`"
                              class="d-flex align-center justify-space-between px-4 fill-height pointer"
                          >
                            <h5 class="pl-6 d-flex align-center" >
                              {{  child.text  }}
                              <wsAddItem
                                  @input="editGroupAction($event,child)"
                                  :hover="hover"
                                  :items="offlineGroupActionsSelect"
                                  inline
                              />
                            </h5>
                            <!--                            <h5 >{{  courseCategoryCount(child.value)   }}-->

                          </v-sheet>



                        </div>

                      </v-hover>
                    </v-sheet>
                  </template>
                </template>
              </template>

            </v-sheet>
            <ws-tooltip v-else  :text="$t('Groups')">
              <v-btn @click="hideCategoriesAction" :color="wsACCENT" icon><v-icon>mdi-file-tree</v-icon></v-btn>
            </ws-tooltip>

          </v-col>
          <!-- Students Table-->

          <v-col style="min-width: 100px; max-width: 100%; position: relative" class="flex-grow-1 flex-shrink-0 pb-6">
            <div style="height: 100%;" :style="`border : 1px solid ${wsBACKGROUND}`" class="d-flex flex-column wsRoundedLight">
              <div   class="flex-grow-1 overflow-y-auto" style="height: 100%;">
                <ws-data-table
                    :items="itemsFiltered"
                    :headers="headers"
                    :search="!ajaxSearch ? $store.state.settings.filtersData.search : null"
                    class="pb-8"
                    @current-items="getExportData"
                    footer-portal="students_footer"
                    :total="total"
                    :load-more="loadMore"
                >
                  <template #item="{item}">

                    <v-hover v-slot="{hover}">

                      <tr @contextmenu="openMenu($event, item)" :style="`background : ${ hover ? wsLIGHTCARD : ''} !important`">

                        <td :style="`border-color: ${wsBACKGROUND}`" class="pl-0">
                          <div class="d-flex align-center text-no-wrap">
                            <v-avatar class="mr-2 ml-1"  :color="wsACCENT"
                                      size="36" key="profile_picture" >
                              <v-img transition="xxx" v-if="item.img" :src="item.img">
                                <template slot="placeholder">
                                  <v-img transition="xxx" src="@/assets/placeholder/user_blank.jpg" />
                                </template>
                              </v-img>
                              <v-icon  dark v-else>mdi-account-tie</v-icon>
                            </v-avatar>
                            <div>
                              <h5 :style="`color : ${wsACCENT}`">{{ item.name }} </h5>
                              <h6
                                  v-if="IS_BUSINESS_OWNER || !MODULE_PERMISSIONS(['MODULE_WESTUDY__HIDE_STUDENT_CONTACTS'], true)"
                                  :style="`color : ${wsACCENT}`"> {{ item.email}}</h6>
                            </div>
                          </div>
                        </td>
                        <td   v-if="IS_BUSINESS_OWNER || !MODULE_PERMISSIONS(['MODULE_WESTUDY__HIDE_STUDENT_CONTACTS'], true)">
                          <h5>{{ item.phone}}</h5>
                        </td>
                        <td :style="`border-color: ${wsBACKGROUND}`" align="center">
                          <ws-date-picker custom-activator  @input="editDateEnrolled($event, item)" >
                            <ws-chip class="text-no-wrap" :text="item.date || $t('Choose')"  icon="mdi-calendar" >
                              <v-icon small>mdi-chevron-down</v-icon>
                            </ws-chip>
                          </ws-date-picker>
                        </td>
                        <td v-if="$store.state.courses.course.is_subscription && $store.state.courses.course.subscription_type === 'time_period' "
                            :style="`border-color: ${wsBACKGROUND}`" align="center">
                          <ws-date-picker custom-activator  @input="editSubscriptionTill($event, item)" >
                            <ws-chip class="text-no-wrap" :text="item.subscription_date || $t('Choose')"  icon="mdi-calendar" >
                              <v-icon small>mdi-chevron-down</v-icon>
                            </ws-chip>
                          </ws-date-picker>
                        </td>
                        <td :style="`border-color: ${wsBACKGROUND}`" v-if="groupsFiltered.length > 0">
                          <ft-select @input="editStudentGroup($event, item)"  :items="groupsFiltered">
                            <h5 :style="!item.group_name ? `color : ${wsDARKLIGHT}` : '' ">
                              {{ item.group_name ? item.group_name : $t('Group') }}
                              <v-icon :color="!item.group_name ? wsDARKLIGHT : wsACCENT" small>mdi-chevron-down</v-icon>
                            </h5>

                          </ft-select>
                        </td>
                        <td :style="`border-color: ${wsBACKGROUND}`" v-if="$store.state.courses.course.online">
                          <ft-select @input="editEnrollmentWave($event, item)"  :items="enrollmentWavesSelect">

                            <h5 class="d-flex" style="min-width: 150px" :style="!item.enrollment_wave_name ? `color : ${wsDARKLIGHT}` : '' ">
                              {{ item.enrollment_wave_name ? item.enrollment_wave_name : $t('Schedule') }}
                              <v-icon :color="!item.group_name ? 'grey' : ''" small>mdi-chevron-down</v-icon>
                            </h5>

                          </ft-select>
                        </td>
                        <td :style="`border-color: ${wsBACKGROUND}`" v-if="$store.state.courses.course.has_payment_plans && $store.state.courses.course.payed">
                          <ft-select @input="editPaymentPlan($event, item)"  :items="paymentPlansSelect">

                            <h5 class="d-flex" style="min-width: 150px" :style="!item.payment_plan_name ? `color : ${wsDARKLIGHT}` : '' ">
                              {{ item.payment_plan_name ? item.payment_plan_name : $t('PaymentPlan') }}
                              <v-icon :color="!item.group_name ? 'grey' : ''" small>mdi-chevron-down</v-icon>
                            </h5>

                          </ft-select>
                        </td>
                        <td :style="`border-color: ${wsBACKGROUND}`" align="center">
                          <ft-select @input="editStatus($event, item)"  :items="CHOICE_ACTIVE">
                            <v-chip  class="my-1 mr-2 pointer" small outlined :color="item.active ? 'green lighten-1' : 'grey'"  >
                              <h4 class="font-weight-medium">{{ item.active ? $t('Active') : $t('NotActive') }}</h4>
                              <v-icon small  > mdi-chevron-down</v-icon>
                            </v-chip>
                          </ft-select>
                        </td>
                        <td :style="`border-color: ${wsBACKGROUND}`" v-if="$store.state.courses.course.payed" align="center">
                          <ft-select @input="editPayment($event, item)"  :items="CHOICE_PAYED">
                            <v-chip  class="my-1 mr-2 pointer" small outlined :color="item.is_purchased ? 'green lighten-1' : 'grey'"  >
                              <h4 class="font-weight-medium">{{ item.is_purchased ? $t('PaymentConfirmed') : $t('PaymentNotConfirmed') }}</h4>
                              <v-icon small> mdi-chevron-down</v-icon>
                            </v-chip>
                          </ft-select>
                        </td>
                        <td :style="`border-color: ${wsBACKGROUND}`" width="10" align="center">
                          <v-progress-circular color="green lighten-2" :value="item.completion" >
                            <h6 class="font-weight-light grey--text">{{ item.completion }}</h6>
                          </v-progress-circular>
                        </td>
                        <td :style="`border-color: ${wsBACKGROUND}`" width="10" align="center">
                          <ft-select
                              @expand="selectedStudent = item; selectedUser = item.user_id"
                              :items="editActionsSelect"
                          >
                            <v-btn  icon :color="wsACCENT"  >
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </ft-select>

                        </td>
                      </tr>
                    </v-hover>
                  </template>
                </ws-data-table>
              </div>
              <!-- Table Footer -->
              <div>
                <v-divider  v-if="itemsFiltered.length > 0" :style="`border-color : ${wsBACKGROUND}`" />
                <portal-target name="students_footer"></portal-target>
                <!-- Context Menu-->
                <ft-select
                    @input="editAction($event, selectedUser)"
                    :items="editActionsSelect"
                    :expanded="displayContextMenu"
                    :x="x"
                    :y="y"
                    absolute
                />
              </div>

            </div>
          </v-col>

        </v-row>
    </template>

    <template #dialog>
      <!-- DIALOG WINDOWS-->
      <ws-dialog v-model="displayGroupDialog"
                 :title="newGroup ? $t('AddNewGroup') : $t('EditNewGroup')"
                 @save="addNewGroup"
      >
        <ws-text-field
            v-model="entityData.name"
            :label="$t('Name')"
            :placeholder="$t('EnterGroupName')"
        ></ws-text-field>

        <ws-select
            v-if="$store.state.courses.course.online && $store.state.courses.course.scheduled"
            v-model="entityData.enrollment_wave_id"
            :items="enrollmentWavesSelect"
            :label="$t('Schedule')"
            :placeholder="$t('ChooseSchedule')"
            class="mt-4"
            :error="groupEnrollmentWaveError"
            @input="groupEnrollmentWaveError = false"
        >

        </ws-select>


      </ws-dialog>
      <!-- Display  Student dialog window -->
      <ws-dialog v-model="displayDialog"
                 :show-save="false"
                 :title="$t('AddStudent')">

        <!--      <ws-accordion-->
        <!--          :items="addStudentSelectActionTypes"-->
        <!--          single-->
        <!--      >-->
        <!--        <template #item.select>-->
        <!--        </template>-->
        <!--        <template #item.add>-->
        <!--        </template>-->
        <!--        <template #item.import>-->
        <!--        </template>-->

        <!--      </ws-accordion>-->

        <v-btn :style="`border: 1px solid ${wsBACKGROUND}`" x-large block @click="displayChooseStudent" height="30"  text  class="noCaps justify-start  py-6 mb-2" >
          <v-icon :color="wsATTENTION"  class="mr-3">mdi-account-multiple</v-icon>
          {{ $t('SelectFromClients') }}
        </v-btn>
        <v-btn :style="`border: 1px solid ${wsBACKGROUND}`" x-large block @click="displayAddStudent" height="30"  text  class="noCaps justify-start py-6 mb-2" >
          <v-icon :color="wsATTENTION"  class="mr-3">mdi-account-plus</v-icon>
          {{ $t('AddNewStudent') }}
        </v-btn>
        <v-btn :style="`border: 1px solid ${wsBACKGROUND}`" x-large block @click="displayImport" height="30"  text  class="noCaps justify-start py-6" >
          <v-icon  :color="wsATTENTION"  class="mr-3">mdi-table-arrow-left</v-icon>
          {{ $t('ImportFromFile') }}
        </v-btn>

      </ws-dialog>
      <!-- Display Add Student dialog window -->
      <ws-dialog v-model="displayNewStudentDialog" width="600"
                 :title="$t('AddNewStudent')"
                 @save="addNewStudent">

        <h5 class="font-weight-medium mb-3">{{ $t('Fullname') }}:</h5>
        <v-row>
          <v-col cols="6">
            <v-text-field
                v-model="entityData.firstname"
                :placeholder="$t('Firstname')"
                :label="$t('Firstname')"
                outlined
                hide-details
                dense   />
          </v-col>
          <v-col cols="6">
            <v-text-field
                v-model="entityData.lastname"
                :placeholder="$t('Lastname')"
                :label="$t('Lastname')"
                hide-details
                outlined
                dense   />
          </v-col>
        </v-row>
        <h5 class="font-weight-medium my-3">{{ $t('Password') }}:</h5>
        <v-row>
          <v-col cols="6">
            <v-text-field
                v-model="entityData.password"
                :placeholder="$t('Password')"
                :label="$t('Password')"
                hide-details
                outlined
                dense   />
          </v-col>
          <v-col cols="6">
            <v-text-field
                v-model="entityData.repeat_password"
                :placeholder="$t('PasswordRepeat')"
                :label="$t('PasswordRepeat')"
                hide-details
                outlined
                dense   />
          </v-col>
        </v-row>
        <h5 class="font-weight-medium my-3">{{ $t('Contacts') }}:</h5>
        <v-row>
          <v-col cols="6">
            <v-text-field
                v-model="entityData.email"
                :placeholder="$t('Email')"
                :label="$t('Email')"
                hide-details
                outlined
                dense   />
          </v-col>
          <v-col cols="6">
            <ws-phone-input hide-details   v-model="entityData.phone" />
          </v-col>
        </v-row>
        <h5 class="font-weight-medium mt-3">{{ $t('AdditionalInfo') }}:</h5>
        <v-row class="mt-1">
          <v-col cols="6">
            <wsDatePicker :placeholder="$t('Birthday')" v-model="entityData.birthday" />
          </v-col>
          <v-col cols="6">
            <v-text-field
                v-model="entityData.telephone"
                :placeholder="$t('Country')"
                :label="$t('Country')"
                hide-details
                outlined
                dense   />
          </v-col>
        </v-row>

        <v-row class="mt-1">
          <v-col cols="6">
            <h5 class="font-weight-medium mb-3">{{ $t('ActiveStatus') }}:</h5>
            <v-select
                v-model="entityData.active"
                :items="CHOICE_ACTIVE"
                :placeholder="$t('ActiveStatus')"
                :label="$t('ActiveStatus')"
                hide-details
                outlined
                dense   />
          </v-col>
          <v-col v-if="course.online" cols="6">
            <h5 class="font-weight-medium mb-3">{{ $t('Schedule') }}:</h5>
            <v-select
                v-model="entityData.enrollment_wave_id"
                :items="enrollmentWavesSelect"
                :placeholder="$t('Schedule')"
                :label="$t('Schedule')"
                hide-details
                outlined
                dense   />
          </v-col>
          <v-col v-if="course.payed" cols="6">
            <h5 class="font-weight-medium mb-3">{{ $t('Payment') }}:</h5>
            <v-select
                v-model="entityData.is_purchased"
                :items="CHOICE_PAYED"
                :placeholder="$t('Payment')"
                :label="$t('Payment')"
                hide-details
                outlined
                dense   />
          </v-col>
          <v-col  cols="6">
            <h5 class="font-weight-medium mb-3">{{ $t('WhenEnrolled') }}:</h5>
            <ws-date-picker v-model="entityData.date"
                            :placeholder="$t('Today')" />
          </v-col>
        </v-row>


      </ws-dialog>
      <!-- Display Choose Student from Clients dialog window -->
      <ws-dialog v-model="displayChooseStudentDialog" width="800"
                 :title="$t('NewStudentFromClients')"
                 @cancel="chooseUsersCancelAction"
                 cancel-action
                 :cancel-text="fromStudentsStep === 0 ? $t('Cancel') : $t('GoBack')"
                 :save-text="fromStudentsStep === 0 ? $t('Next') : $t('Save')"
                 @save="addUsersToCourseAction">



        <ws-text-field v-if="fromStudentsStep === 0 "  :placeholder="$t('Search')" v-model="search" prepend-inner-icon="mdi-magnify"/>


        <v-data-table
            v-if="fromStudentsStep === 0 " :items="users"
            v-model="usersSelect"
            :headers="addFromClientsHeaders"
            :search="search"
            class="mt-6 wsHoverLight"
            item-key="uuid"
            hide-default-header
        >

          <template  v-slot:body="{ items, select , isSelected}" :customAttribute="item.uuid">
            <thead >
            <tr :style="`background : ${wsLIGHTCARD}`" class="wsRoundedLightTop" >
              <th class="px-3 py-2 wsRoundedHalfTop " colspan="3" >
                <div class="d-flex justify-space-between align-center ">
                  <div>
                    <v-btn @click="usersSelect = usersSelect.length < items.length ? items : []" small icon  >
                      <v-icon small :color="isSelected ? wsDARK : '' " >
                        {{ isSelected() ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline" }}
                      </v-icon>
                    </v-btn>
                    <span :style="`color : ${wsACCENT}`"  class="ml-8 mr-3">
                     <span v-if="usersSelect.length > 0">{{ $t('Selected') }} : {{ usersSelect.length }}</span>
                     <span v-else>{{ $t('Student')}}</span>
                    </span>
                  </div>
                  <span :style="`color : ${wsACCENT}`" class="mr-6"> {{ $t('Total') }} : {{ users.length }} </span>
                </div>
              </th>
            </tr>
            </thead>
            <tbody >
<!--            <drag-select :key="'student_multiselect_' + dragSelectKey"-->
<!--                         @change="dragSelectStudents($event)" attribute="customAttribute" style="cursor : pointer">-->
              <tr   v-for="(item) in items" :key="item.uuid"
                    @click.stop="select(item, !isSelected(item) )"
                    :customAttribute="item.uuid"
                    :style="`background-color: ${ isSelected(item) ? wsLIGHTACCENT : ''}`"
                    class="pointer">
                <td class="px-3 " :style="`border-bottom: 1px solid ${wsBACKGROUND}` " >
                  <v-btn  icon  >
                    <v-icon  :color="isSelected ? wsDARK : '' " >
                      {{ isSelected(item) ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline" }}
                    </v-icon>
                  </v-btn>
                </td>
                <td WIDTH="100%" class="pt-2 pb-2" valign="center" :style="`border-bottom: 1px solid ${wsBACKGROUND}` ">
                  <div class="d-flex align-center text-no-wrap">
                    <v-avatar class="mr-2 ml-1"  :color="wsACCENT"
                              size="36" key="profile_picture" >
                      <v-img v-if="item.img" :src="item.img" transition="xxx">
                        <template slot="placeholder">
                          <v-img src="@/assets/placeholder/user_blank.jpg" transition="xxx" />
                        </template>
                      </v-img>
                      <v-icon  dark v-else>mdi-account-tie</v-icon>
                    </v-avatar>
                    <div>
                      <h5 :style="`color : ${wsACCENT}`">{{ item.name }} </h5>
                      <h6 :style="`color : ${wsACCENT}`"> {{ item.email}}</h6>
                    </div>
                  </div>
                </td>
                <td class="pr-5" :style="`border-bottom: 1px solid ${wsBACKGROUND}` ">
                  <h5> {{ item.phone }}</h5>
                </td>

              </tr>
<!--            </drag-select>-->
            </tbody>
          </template>



        </v-data-table>

        <div v-else>
          <h3> {{ $t('Settings') }}</h3>

          <v-row class="mt-1">
            <v-col cols="6">
              <h5 class="font-weight-medium mb-3">{{ $t('ActiveStatus') }}:</h5>
              <v-select
                  v-model="entityData.active"
                  :items="CHOICE_ACTIVE"
                  :placeholder="$t('ActiveStatus')"
                  :label="$t('ActiveStatus')"
                  hide-details
                  outlined
                  dense   />
            </v-col>
            <v-col v-if="course.online" cols="6">
              <h5 class="font-weight-medium mb-3">{{ $t('Schedule') }}:</h5>
              <v-select
                  v-model="entityData.enrollment_wave_id"
                  :items="enrollmentWavesSelect"
                  :placeholder="$t('Schedule')"
                  :label="$t('Schedule')"
                  hide-details
                  outlined
                  dense   />
            </v-col>
            <v-col v-if="course.payed" cols="6">
              <h5 class="font-weight-medium mb-3">{{ $t('Payment') }}:</h5>
              <v-select
                  v-model="entityData.is_purchased"
                  :items="CHOICE_PAYED"
                  :placeholder="$t('Payment')"
                  :label="$t('Payment')"
                  hide-details
                  outlined
                  dense   />
            </v-col>
            <v-col  cols="6">
              <h5 class="font-weight-medium mb-3">{{ $t('WhenEnrolled') }}:</h5>
              <ws-date-picker v-model="entityData.date"
                              :placeholder="$t('Today')" />
            </v-col>
          </v-row>

        </div>

      </ws-dialog>
      <!-- Display Import Student from Clients dialog window -->
      <ws-dialog v-model="displayImportStudentDialog"
                 :width="importComplete ? '900' : '600' "
                 :title="$t('ImportStudents')"
                 :showSave="!displayImportStudentDialog"
                 :cancelText="importCancelText"
                 @cancel="importCancelButton"
                 cancel-action
                 @save="addNewStudent">

        <div v-if="!importComplete " >

          <v-btn block class="noCaps mb-4" outlined :color="wsACCENT" download :href="'https://' + $store.state.second_hostname + '/library/import_examples/import_table_example_students.xlsx'">
            {{ $t('DownloadImportExample') }}
            <v-icon>mdi-download</v-icon>
          </v-btn>

          <h5 class="text-center font-weight-light"> {{$t('UploadNewUsersTableInFormats')}} :  </h5>
          <h5 class="text-center mt-3"> .xls, .xlsx </h5>
          <wsImageUpload class="mt-6" @upload="uploadUsers" />
        </div>
        <div v-if="importComplete && !reviewErrors">

          <h3 :style="`color: `" class="mb-5 mb-2 text-center"> {{ $t('ImportComplete')}}</h3>

          <v-simple-table dense>
            <tbody>
            <!-- File -->
            <tr>
              <td width="50px">
                <h5 class="font-weight-medium">{{ $t('File') }}</h5>
              </td>
              <td>
                <div class="d-flex align-center">
                  <v-icon color="grey darken-3" small class="mr-1">mdi-file</v-icon>
                  <h5 class="font-weight-medium" >{{ importResult.file}}</h5>
                </div>
              </td>
            </tr>
            <!-- New Users-->
            <tr>
              <td width="60px" class="py-3 text-no-wrap">
                <div class="d-flex align-center">
                  <v-icon :color="wsDARKER" small class="mr-1">mdi-account</v-icon>
                  <h5>{{$t('NewUsers')}}</h5>
                </div>
              </td>
              <td>
                <h5>{{ importResult.users_created_count}}</h5>
              </td>
            </tr>
            <!-- New Teacher Profiles-->
            <tr v-if="importResult.teacher_profiles_count > 0">
              <td width="50px" class="py-3 text-no-wrap">
                <div class="d-flex align-center">
                  <v-icon  small class="mr-1">mdi-account-tie</v-icon>
                  <h5>{{ $t('TeacherProfiles') }}</h5>
                </div>
              </td>
              <td>
                <h5>{{ importResult.teacher_profiles_count }}</h5>
              </td>
            </tr>
            <!-- Failed Rows -->
            <tr>
              <td width="50px">
                <h5 class="font-weight-medium">{{ $t('FailedRows') }}</h5>
              </td>
              <td>
                <h5 class="font-weight-medium" >{{  importResult.failed.length }}</h5>
              </td>
            </tr>
            <tr>
              <td width="50px">
                <h5 class="font-weight-medium">{{ $t('ImportErrors') }}</h5>
              </td>
              <td>
                <h5 class="font-weight-medium" >{{importResult.import_errors.length }}</h5>
              </td>
            </tr>
            </tbody>
          </v-simple-table>


          <v-btn @click="reviewErrors = true;"
                 class="mt-6 noCaps"
                 elevation="0"
                 block :color="wsDARK" dark>{{ $t('Review') }}
          </v-btn>

        </div>
        <div v-if="reviewErrors">

          <!-- New Users-->
          <div v-if="importResult.new_users.length > 0">
            <h4 class="text-center">{{ $t('NewUsers') }}: </h4>
            <v-simple-table class="mt-3 mb-3">
              <tbody>
              <tr v-for="(item,i) in importResult.new_users" :key="i + 'users'">
                <td width="1px" class="text-no-wrap px-1">
                  <h5 class="font-weight-light">{{ i + 1 }}</h5>
                </td>
                <td  width="1px" class="text-no-wrap px-2" >
                  <h5>{{ item.name }} </h5>
                </td>
                <td  width="1px" class="text-no-wrap px-2" >
                  <h6 class="font-weight-light">{{ item.email }} {{item.phone}}</h6>
                </td>
                <td class="pl-6">
                  <div class="d-flex">
                    <v-icon small :color="wsDARK" class="mr-2">mdi-safe</v-icon>
                    <h5 class="font-weight-light">{{ $t(item.password)}}</h5>
                  </div>
                </td>
                <td>
                  <h5 v-if="item.department" class="font-weight-light">
                    {{ item.department }}
                  </h5>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </div>
          <!-- Import Fails-->
          <div v-if="importResult.failed.length > 0">
            <h4 class="text-center">{{ $t('ImportFailedRows') }}: </h4>
            <v-simple-table class="mt-3 mb-3">
              <tbody>
              <tr v-for="(row,i) in importResult.failed" :key="i">
                <td width="1px" class="text-no-wrap px-1">
                  <h5 class="font-weight-light">{{ row.index }}</h5>
                </td>
                <td>
                  <div class="d-flex">
                    <v-icon small :color="wsATTENTION" class="mr-2">mdi-alert-circle-outline</v-icon>
                    <h5 class="mr-5"> {{ $t('ErrorsInFields') }}:</h5>
                    <h5 v-for="field in row._error_fields" :key="field"
                        class="font-weight-light mr-3">
                      {{ field }}
                    </h5>
                  </div>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </div>
          <!-- Import Errors-->
          <div v-if="importResult.import_errors.length > 0">
            <h4 class="text-center">{{ $t('DatabaseImportError') }}: </h4>
            <v-simple-table class="mt-3">
              <tbody>
              <tr v-for="(row,i) in importResult.import_errors" :key="i">
                <td width="1px" class="text-no-wrap px-1">
                  <h5 class="font-weight-light">{{ row.index }}</h5>
                </td>
                <td  width="1px" class="text-no-wrap px-2" >
                  <h5>{{row.row.sirname}} {{ row.row.name }} {{ row.row.middlename }} </h5>
                  <h6 class="font-weight-light">{{ row.row.email }} {{row.row.phone}}</h6>
                </td>
                <td class="pl-6">
                  <div class="d-flex">
                    <v-icon small :color="wsATTENTION" class="mr-2">mdi-alert-circle-outline</v-icon>
                    <h5 class="font-weight-light">{{ $t(row.error)}}</h5>
                  </div>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </div>

        </div>


      </ws-dialog>
      <!-- Display Delete Student dialog window -->
      <ws-dialog
          v-model="displayStudentDelete"
          @save="deleteStudent"
          :save-text="$t('Delete')"
          :title="$t('DeleteStudent')"
      >
        <h5 class="mb-5 text-center"> {{ $t('ConfirmStudentDeleteMessage') }}</h5>
        <h5 class="mb-5 text-center"> {{ $t('ConfirmStudentDeleteInstruction') }}</h5>

        <h4 class="mb-5 text-center"> {{ selectedStudentDelete.email }}</h4>
        <ws-text-field
            v-model="deleteStudentData"
        />

      </ws-dialog>
    </template>

  </sub-page>
</template>

<script>
import wsImageUpload from "@/components/UI/wsImageUpload";
import {mapActions, mapMutations, mapState} from "vuex";
// import DragSelect from "drag-select-vue";
import spectatorMode from "@/mixins/spectatorMode";

export default {
  name: "courseStudents",
  props : ['uuid'],
  mixins : [spectatorMode],
  components : {
    wsImageUpload,
    // DragSelect
  },
  data() {
    return {
      initialLength : 0,

      total : 0,
      displayContextMenu : false,
      x : 0,
      y : 0,
      selectedStudent : {},
      selectedUser : null,

      selectedStudentDelete : {},
      displayStudentDelete : false,
      deleteStudentData : '',

      categoriesWidth : 280,
      hideCategories : false,

      loading : true,
      filter : {},
      displayGroupDialog : false,
      newGroup : false,
      groups : [],
      selectedGroup : {},
      groupEnrollmentWaveError : false,

      // import variables
      fromStudentsStep : 0,
      importResult : {},
      importComplete : false,
      reviewErrors : false,
      search: '',
      users : [],
      usersSelect : [],
      items : [],
      enrollmentWavesSelect : [],
      paymentPlansSelect : [],
      entityDataDefault : {
        name : '',
        firstname : '',
        password : ''
      },
      entityData : {},
      displayDialog: false,
      displayNewStudentDialog : false,
      displayChooseStudentDialog : false,
      displayImportStudentDialog : false,
      dragSelectKey : 0,
      newEntity : true,

      reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/ ,

    }
  },
  computed : {
    ...mapState('courses',[ 'course', 'selectedLang' ]),

    ajaxSearch() {
      return this.initialLength > 200
    },

    filtersValues() {
      return this.$store.state.settings.filtersData
    },
    addFromClientsHeaders() {
      return [
        { value : 'select' },
        { value : 'name'   },
        { value : 'total'  } ,
        { value : 'email'  },
        { value : 'phone'  }
      ]
    },
    editActionsSelect() {
      return [
        { text : this.$t('ViewClientCard') ,
          icon : 'mdi-account-multiple' ,
          route : this.businessDashLink('subscribers/' + this.selectedUser) + '/timeline'
        },
        { text : this.$t('LogInAsUser')   ,
          icon : 'mdi-login',
          action : this.loginAsStudent
        },
        { text : this.$t('Delete'),
          icon : 'mdi-delete' ,
          action : () => this.openDeleteStudent(this.selectedStudent),
          color : this.wsWARNING}
      ]
    },
    addStudentSelectActionTypes() {
      return [
        { text : this.$t('SelectFromClients') , value : 'select' , icon : 'mdi-account-multiple'},
        { text : this.$t('AddNewStudent') , value : 'add' , icon : 'mdi-account-plus'},
        { text : this.$t('ImportFromFile') , value : 'import' , icon : 'mdi-table-arrow-left'}
      ]
    },

    offlineGroupActionsSelect() {
      return [
        { text : this.$t('EditName') , value : 'edit' , icon : 'mdi-pencil' },
        { text : this.$t('Delete') , value : 'delete'        , icon : 'mdi-delete-outline' },
      ]
    },
    actionSelect() {
      return [
        { text : this.$t('AddStudents') , value : this.displayNewStudent },
        { text : this.$t('AddGroup') , value : this.displayNewGroup }
      ]
    },
    filtersItems() {
      let items = [];

      items = [{text : this.$t('ProfileStatus') , value : 'active' , type : 'select' , items : this.CHOICE_ACTIVE}]

      if (this.$store.state.courses.course.online) {
        let choiceItems  = JSON.parse(JSON.stringify(this.enrollmentWavesSelect))
        choiceItems.push({ text : this.$t('None') , value : 'null' })
        items.push( {text : this.$t('Schedule') , value : 'schedule' , type : 'select' , items : choiceItems } )
      }
      if (this.$store.state.courses.course.payed) {
        items.push( {text : this.$t('PaymentStatus') , value : 'is_purchased' , type : 'select' , items : this.CHOICE_PAYED} )
      }
      if (this.$store.state.courses.course.has_payment_plans) {
        let choiceItems  = JSON.parse(JSON.stringify(this.paymentPlansSelect))
        choiceItems.push({ text : this.$t('None') , value : 'null' })
        items.push( {text : this.$t('PaymentPlan') , value : 'payment_plan' , type : 'select' , items : choiceItems } )
      }

      if ( this.groupsFiltered.length > 0 ) {
        let choiceItems  = JSON.parse(JSON.stringify(this.groupsFiltered))
        choiceItems.push({ text : this.$t('None') , value : 'null' })
        items.push( {text : this.$t('Group') , value : 'group' , type : 'select' , items : choiceItems } )
      }

      return items
    },
    importCancelText() {
      if (this.importComplete ) { return this.reviewErrors ? this.$t('Back') : this.$t('Finish') }
      if (this.reviewErrors) { return this.$t('Back') }
      return this.$t('Back')
    },
    saveText() {
      return this.newEntity ? this.$t('Add') : this.$t('Save')
    },
    headers(){

      let items = [
        { text : this.$t('Name')  , value : 'name'  ,    align : 'center'                 },

        { text : this.$t('Registered') , value : 'date' , width : 10, align : 'center'      },
      ]

      if ( this.IS_BUSINESS_OWNER || !this.MODULE_PERMISSIONS(['MODULE_WESTUDY__HIDE_STUDENT_CONTACTS'], true) ) {
        items.splice(1,0,  { text : this.$t('Phone') , value : 'phone' , align : 'center' },)
      }

      if ( this.$store.state.courses.course.is_subscription && this.$store.state.courses.course.subscription_type === 'time_period') {
        items.push( { text : this.$t('SubscriptionTill') , value : 'subscription_date'})
      }

      if ( this.groupsFiltered.length > 0 ) {
        items.push({ text : this.$t('Group') , value : 'group_name'})
      }

      if (this.$store.state.courses.course.online) {
        items.push({ text : this.$t('Schedule') , value : 'enrollment_wave_name' });
      }
      if (this.$store.state.courses.course.has_payment_plans && this.$store.state.courses.course.payed) {
        items.push({ text : this.$t('PaymentPlan') , value : 'payment_plan_name' , align : 'center'        });
      }
      items.push(   { text : this.$t('ActiveStatus') , value : 'active' , align : 'center' , width : 10,         })
      if (this.$store.state.courses.course.payed) {
        items.push({ text : this.$t('Payment') , value : 'is_purchased' , align : 'center'    } );
      }
      items.push( { text : this.$t('Completion') , value : 'completion' , width : 10, align : 'center'         })

      items.push( { value : 'delete'} )

      items.push(  { value : 'email' , align : 'd-none'} )
      return items

    } ,
    // Filters And Selects
    itemsFiltered() {
      let items = this.items

      if ( this.ajaxSearch ) {
        return this.items
      }

      let filters = this.$store.state.settings.filtersData
      if (filters.active !== null && filters.active !== undefined ) {
        items = items.filter(el => el.active === filters.active )
      }

      if ( filters.schedule ) {
        let filterValue = filters.schedule !== 'null' ? filters.schedule : null
        items = items.filter( el=> el.enrollment_wave_id === filterValue )
      }

      if ( filters.payment_plan ) {
        let filterValue = filters.payment_plan !== 'null' ? filters.payment_plan : null
        items = items.filter( el=> el.payment_plan_id === filterValue )
      }

      if ( filters.group ) {
        let filterValue = filters.group !== 'null' ? filters.group : null
        items = items.filter( el=> el.group_id === filterValue )
      }

      if ( filters.is_purchased !== null && filters.is_purchased !== undefined  ) {
        items = items.filter( el=> el.is_purchased === filters.is_purchased )
      }

      return items
    },
    groupsFiltered() {
      if (this.groups.length === 0 ) {
        return []
      }
      if (!this.$store.state.courses.course.online) {
        return this.groups.filter(el=> !el.enrollment_wave_id )
      }
      if (this.$store.state.courses.course.online && this.$store.state.courses.course.online) {
        return this.groups.filter(el=> el.enrollment_wave_id )
      }

      return []

    }
  },
  watch : {
    filtersValues : {
      handler() {
        this.initPage()
      },
      deep : true
    }
  },
  methods : {

    ...mapActions('courses',[
        'EDIT_STUDENT_PROFILE',
        'ADD_STUDENT_PROFILE',
        'DELETE_STUDENT_PROFILE',
        'ADD_USERS_TO_COURSE',
        'ADD_COURSE_GROUP',
        'DELETE_COURSE_GROUP',
        'GET_COURSE_STUDENTS',
        'GET_ENROLLMENT_WAVES_SELECT',
        'GET_COURSE_FREE_USERS_SELECT',
        'GET_COURSE_PAYMENT_PLANS_SELECT'
    ]),
    ...mapActions('import',['UPLOAD_IMPORT_USERS']),
    ...mapMutations('courses' , ['INIT_PAGE']),
    ...mapActions('export',[
      'EXPORT_TABLE_DATA'
    ]),

    searchStudents() {
      let result = [
          {text : 'pedro' , value : 'pedro' },
          {text : 'shantal' , value : 'soso' }
      ]
      return result
    },

    async loadMore(offset , itemPerPage) {

      if ( (offset + 1) > this.items.length && (offset < this.total) ) {

        let result = await this.GET_COURSE_STUDENTS({
          offset : offset ,
          uuid : this.uuid,
          limit : itemPerPage
        })
        if ( !result ) {
          this.loading = false
          return
        }
        this.items = [...this.items , ...result.students]
      }

    },

    displayAction($event) {

      switch ($event) {
        case 'select'  :  this.displayChooseStudent() ; break;
        case 'add'     :  this.displayAddStudent(); break;
        case 'import'  :  this.displayImport();  break;
        default :  this.notify($event)
      }
    },

    getExportData($event) {
      this.itemsForExport = $event
    },

    async exportData() {
      let headers = this.COPY(this.headers)
      headers.unshift({ text : this.$t('Email') , value : 'email' })
      let data = {
        data : this.itemsForExport,
        headers : headers
      }
      let blob = await this.EXPORT_TABLE_DATA(data)
      if ( !blob ) {
        return
      }
      var url  = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = 'import_data.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();

      this.notify(this.$t('TableExported'))

    },

    loginAsStudent() {
      this.loginAsUser(this.selectedUser , this.businessDashLink(`courses/${this.uuid}/students`))
    },

    openMenu($event , item) {
      this.selectedUser = item.user_id
      this.selectedStudent = item
      $event.preventDefault()
      this.displayContextMenu = false
      this.x = $event.clientX
      this.y = $event.clientY
      this.$nextTick(() => {
        this.displayContextMenu = true
      })
    },

    openDeleteStudent(item) {
      this.selectedStudentDelete = JSON.parse(JSON.stringify(item)),
      this.deleteStudentData = ''
      this.displayStudentDelete = true
    },
    async deleteStudent() {
      if ( this.selectedStudentDelete.email !== this.deleteStudentData ) {
        return this.notify(this.$t('ConfirmDeleteStudentEmailError') , 'warning')
      }
      let result = await this.DELETE_STUDENT_PROFILE(this.selectedStudentDelete.uuid)
      if ( !result ) {
        return this.notify(this.$t('NetworkError') , 'error' )
      }
      let index = this.items.findIndex(el=> el.uuid === this.selectedStudentDelete.uuid )
      if ( index === -1 ) {
        return
      }
      this.items.splice( index , 1 )
      this.notify(this.$t('StudentProfileDeleted'))
      this.displayStudentDelete = false
    },

    selectGroup(groupId) {
      this.$store.state.settings.filtersData.group = groupId
      this.$store.state.settings.filtersData = JSON.parse(JSON.stringify(this.$store.state.settings.filtersData))
    },
    editGroupAction(action,item) {
      if ( action === 'edit' ) {
        this.displayEditGroup(item)
      }
      if ( action === 'delete' ) {
        this.deleteGroup(item)
      }
    },
    async deleteGroup(item) {
      let result = this.DELETE_COURSE_GROUP(item.value)
      if ( !result ) {
        return
      }
      let index = this.groups.findIndex(el=>el.value === item.value)
      if ( index === -1 ) {
        this.displayGroupDialog = false
        return
      }
      if (this.items.length > 0) {
        this.items.forEach(student => {
          if ( student.group_id === item.value) {
            student.group_id = null
            student.group_name = null
          }
        })
        this.items = JSON.parse(JSON.stringify(this.items))
      }
      this.groups.splice(index,1)

      if (this.groupsFiltered.length === 0 ) {
        this.hideCategoriesAction()
      }
    },
    async addNewGroup() {

      if ( this.$store.state.courses.course.online && this.$store.state.courses.course.scheduled && !this.entityData.enrollment_wave_id ) {
        this.groupEnrollmentWaveError = true
        return this.notify(this.$t('EnrollmentWaveNotSet'))
      }

      let result = await this.ADD_COURSE_GROUP(this.entityData)
      if ( !result ) {
        return
      }
      if ( this.newGroup ) {
        this.groups.push(result)
      } else {
        let index = this.groups.findIndex(el=>el.value === result.value)
        if ( index === -1 ) {
          this.displayGroupDialog = false
          return
        }
        this.groups[index] = result
        this.$set(this.groups, index, result)

        if (this.items.length > 0) {
          this.items.forEach(student => {
            if ( student.group_id === result.value) {
              student.group_name = result.text
            }
          })
          this.items = JSON.parse(JSON.stringify(this.items))
        }

      }
      this.displayGroupDialog = false
    },
    displayEditGroup(item) {
      this.newGroup = false
      this.selectedGroup = item
      this.entityData = {
        uuid : item.value,
        name : item.text,
        course_id : this.uuid,
        enrollment_wave_id : item.enrollment_wave_id
      }

      this.displayGroupDialog = true
    },
    displayNewGroup() {
      this.newGroup = true
      this.entityData = {
        course_id : this.uuid,
        lang : this.selectedLang
      }
      this.displayGroupDialog = true
    },
    chooseUsersCancelAction() {
      if ( this.fromStudentsStep === 0 ) {
        this.displayChooseStudentDialog = false
      } else {
        this.fromStudentsStep = 0
      }
    },
    addUsersToCourseAction() {

      if ( this.fromStudentsStep === 0 ) {
        if (this.usersSelect.length === 0 ) {
          return this.notify(this.$t('NoUsersSelected'))
        }
        this.fromStudentsStep++
        return
      } else {
        if ( this.fromStudentsStep === 1  ) {
          this.addProfilesFromUsersList()
        }
      }

    },
    async addProfilesFromUsersList() {
      this.$store.state.settings.filtersData = {}
      this.entityData.course_id = this.uuid
      this.entityData.users = this.usersSelect.map(el => el.uuid)

      let result = await this.ADD_USERS_TO_COURSE(this.entityData)
      if ( !result || result === true ) { return }
      this.notify(this.$t('StudentProfileCreated') , 'success' )
      this.initPage()

      this.displayChooseStudentDialog = false


    },
    dragSelectStudents($event) {
      if ( $event.length > 1 ) {
        let selectedItems = this.users.filter( el => $event.includes(el.uuid))
        if ( !this.SHIFT_DOWN) {
          this.usersSelect = []
          this.usersSelect =  selectedItems
        } else {
          let selectedIds = this.usersSelect.map( el => el.uuid )
          selectedItems = selectedItems.filter( el => !selectedIds.includes(el.uuid) )
          this.usersSelect = this.usersSelect.concat(selectedItems)
        }
      }
    },
    async uploadUsers(file) {
      this.$store.state.settings.filtersData = {}
      this.importComplete     = false
      this.importResult = {
        import_errors : [],
        failed : [],
        new_users : []
      }
      let data = {
        data : { course : this.uuid } ,
        fileFormData : file
      }
      let result = await this.UPLOAD_IMPORT_USERS(data)
      if ( !result ) { return false }
      this.notify(this.$t('ImportFinished'))
      this.initPage()
      this.importComplete = true
      this.importResult = result
    },
    displayAddStudent() {
      this.entityData = {}
      this.newEntity = true
      this.displayDialog = false
      this.displayNewStudentDialog = true
    },
    async displayChooseStudent() {
      this.fromStudentsStep = 0
      this.entityData
      let result = await this.GET_COURSE_FREE_USERS_SELECT(this.uuid)
      if ( result && result !== true ) {  this.users = result }
      this.newEntity = true
      this.displayDialog = false
      this.displayChooseStudentDialog = true
    },
    displayImport() {
      this.newEntity = true
      this.displayDialog = false
      this.displayImportStudentDialog = true
    },
    displayNewStudent() {
      this.fieldsErrors = {}
      this.entityData = {}
      this.displayDialog = true

    },
    async addNewStudent() {
      let error = [];

      if ( !this.entityData.email ) {
        this.fieldsErrors.email = 'Email'
        error.push('EnterEmail')
      } else {
        this.entityData.email = this.entityData.email.replace(/ /g,'');
        if(  !this.reg.test(this.entityData.email) ) {
          error.push('WrongEmail')
        }
      }

      if ( !this.entityData.phone ) {
        error.push('EnterPhone')
      }
      if ( !this.entityData.firstname ) {
        error.push('EnterFirstname')
      }
      if ( !this.entityData.lastname ) {
        error.push('EnterLastname')
      }
      if ( !this.entityData.password ) {
        error.push('EnterPassword')
      }
      if ( this.entityData.password && ( this.entityData.password !== this.entityData.repeat_password)  ) {
        error.push('PasswordRepeatNotMatch')
      }

      if (error.length > 0 ) { return this.notify( this.$t(error[0]) , 'warning' ) }

      this.entityData.course_id = this.$store.state.courses.course.uuid;
      let result = await this.ADD_STUDENT_PROFILE(this.entityData)
      if ( !result ) {
        let error = this.$t('Error')
        if ( this.$store.state.ajax.error === 'User already exist') {
          error = this.$t('UserWithSelectedPhoneOrEmailAlreadyExist')
        }
        return this.notify(error)
      }
      this.items.unshift(result)
      this.notify(this.$t('StudentProfileCreated') , 'success' )
      this.displayNewStudentDialog = false
    },
    async editPayment(payed, item) {
      let data = {
        uuid : item.uuid,
        is_purchased : payed
      }
      let result = await this.EDIT_STUDENT_PROFILE(data)
      if ( !result ) { return this.notify('Error') }
      item.is_purchased = payed
      this.notify(this.$t('ChangesSaved'))
    },
    async editStatus(status, item) {
      let data = {
        uuid : item.uuid,
        active : status
      }
      let result = await this.EDIT_STUDENT_PROFILE(data)
      if ( !result ) { return this.notify('Error') }
      item.active = status
      this.notify(this.$t('ChangesSaved'))
    },
    async editDateEnrolled(date, item) {
      let data = {
        uuid : item.uuid,
        date : date
      }

      let result = await this.EDIT_STUDENT_PROFILE(data)
      if ( !result ) { return this.notify('Error') }
      item.date = date
      this.notify(this.$t('ChangesSaved'))
    },
    async editSubscriptionTill(date, item) {
      let data = {
        uuid : item.uuid,
        subscription_date : date
      }

      let result = await this.EDIT_STUDENT_PROFILE(data)
      if ( !result ) { return this.notify('Error') }
      item.subscription_date = date
      this.notify(this.$t('ChangesSaved'))
    },
    async editEnrollmentWave(uuid, item) {
      let data = {
        uuid : item.uuid,
        enrollment_wave_id : uuid
      }
      let result = await this.EDIT_STUDENT_PROFILE(data)
      if ( !result ) { return this.notify('Error') }
      item.enrollment_wave_name = result.enrollment_wave_name
      item.enrollment_wave_id = result.enrollment_wave_id
      this.notify(this.$t('ChangesSaved'))
    },
    async editStudentGroup(uuid, item) {
      let data = {
        uuid : item.uuid,
        group_id : uuid
      }
      let result = await this.EDIT_STUDENT_PROFILE(data)
      if ( !result ) { return this.notify('Error') }
      item.group_name = result.group_name
      item.group_id = result.group_id
      this.notify(this.$t('ChangesSaved'))
    },
    async editPaymentPlan(uuid, item) {
      let data = {
        uuid : item.uuid,
        payment_plan_id : uuid
      }
      let result = await this.EDIT_STUDENT_PROFILE(data)
      if ( !result ) { return this.notify('Error') }
      item.payment_plan_name = result.payment_plan_name
      item.payment_plan_id = result.payment_plan_id
      this.notify(this.$t('ChangesSaved'))
    },
    hideCategoriesAction() {
      if (this.hideCategories) {
        this.hideCategories = false
        this.categoriesWidth = 280
        return
      }
      if (!this.hideCategories) {
        this.hideCategories = true
        this.categoriesWidth = 50
        return
      }
    },
    flushEntity() {
      this.entityData = JSON.parse(JSON.stringify(this.entityDataDefault))
      if (this.entityType === 'institute') {
        this.entityData.institute_id = this.uuid
        this.entityData = JSON.parse(JSON.stringify(this.entityData))

      }
    },
    saveChanges() {

      this.newEntity ? this.addItem(this.ADD_ENROLLMENT_WAVE(this.entityData))
          : this.changeItem(this.ADD_ENROLLMENT_WAVE(this.entityData))
      this.displayDialog = false
      this.flushEntity()

    },
    deleteItem() {

      this.removeItem(this.DELETE_ENROLLMENT_WAVE(this.entityData.uuid) )
      this.displayDialog = false

    },
    async addItem(item) {
      let result = await item
      if (!result) { return this.notify( this.$t('NetworkError') ) }
      this.items.push(result)
      this.notify( this.$t('NewSchedulePlanCreated') )
    },
    async changeItem(item) {
      let result = await item

      if (!result) { return this.notify( this.$t('NetworkError') ) }

      var index = this.items
          .findIndex(el => el.uuid === result.uuid )
      if (index === -1 ) { return }

      this.items[index] = result
      this.$set(this.items,index,result)
      this.notify( this.$t('ChangesSaved') )
    },
    async removeItem(deleteAction) {

      if ( !await deleteAction ) {
        return this.notify(this.$t('EntityCannotBeDeleted'))
      }

      var index = this.items
          .findIndex(el => el.uuid === this.entityData.uuid )
      if (index === -1 ) { return }
      this.items.splice(index,1)
      this.notify(this.$t('EnrollmentScheduleDeleted'))

    },
    displayNewItem(){
      this.flushEntity()
      this.newEntity = true
      this.entityData.course_id = this.uuid
      this.displayDialog = true

    },
    async displayEditItem(item) {
      this.displayDialog = true;
      this.newEntity = false
      this.entityData = await this.GET_ENROLLMENT_WAVE(item.uuid)
      this.entityData.course_id = this.uuid
      //    this.entityData = JSON.parse(JSON.stringify(this.entityData))
    },
    importCancelButton() {

      if (this.reviewErrors) {
        this.reviewErrors = false
        return
      }

      this.displayImportStudentDialog = false
      this.displayDialog = true

      if (this.importComplete) {
        this.importComplete = false
        this.reviewErrors = false
        this.importResult = false
      }
    },
    getEnrollmentWaveGroups(groupId) {
      if (this.groups.length === 0 ) {
        return []
      }
      return this.groups.filter(el=> el.enrollment_wave_id === groupId)
    },
    paginationSelect(props) {
      let items = []
      if ( props.itemsPerPageOptions) {
        props.itemsPerPageOptions.forEach(item=>{
          items.push( { text : (item !== -1 ? item : this.$t('All'))  , value : item  })
        })
      }
      return items
    },
    displayPaginationCondition(props) {
      return props.pagination.pageCount > 1 || this.itemsPerPageIndex(props) !== 0
    },

    async initPage(noFilters = false) {
      let filters = this.filtersValues
      if ( noFilters ) {
        filters = null
      }
      this.flushEntity()

      let data = {
        uuid : this.uuid
      }
      if ( filters ) {
        data.filters = filters
      }
      let result =  await this.GET_COURSE_STUDENTS(data)
      if ( !result ) {
        this.loading = false
        return
      }

      this.items = result.students
      this.groups = result.groups
      this.enrollmentWavesSelect = result.enrollment_waves
      this.paymentPlansSelect = result.payment_plans
      this.total = parseInt(result.students_total)

      if ( noFilters ) {
        this.initialLength = result.students_total
      }

      this.loading = false

      if (this.groupsFiltered.length ===0 ) {
        this.hideCategoriesAction()
      }


    }
  },
  mounted() {
    this.$store.state.settings.filtersData = {}
    this.initPage(true)
  },
  beforeDestroy() {
    this.$store.state.settings.filtersData = {}
  }
}

</script>

<style scoped>

</style>